import React, { FC, useState, useCallback, useRef, Dispatch, SetStateAction } from 'react';
import { useStaticContent } from '~/libs/queries/bff';
import { Link } from '~/shared/link';
import { Svg } from '~/shared/svg';
import useTranslations from '~/shared/hooks/use-translations.hook';

import styles from './header-root.module.scss';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import { signIn } from 'next-auth/react';

interface IProps {
    hideLogInButton?: boolean;
}

const HeaderRoot: FC<IProps> = ({ hideLogInButton }) => {
    const translate = useTranslations();
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { loggedInPageUrl } = useStaticContent();
    const timer = useRef(null);

    // Start a fake loader on click
    const startLoading = useCallback((stateAction: Dispatch<SetStateAction<boolean>>) => {
        stateAction(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        timer.current = setTimeout(() => {
            stateAction(false);
        }, 5000);
    }, []);

    const handleSignIn = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        startLoading(setIsLoadingLogin);

        signIn('identity-server4', { callbackUrl: loggedInPageUrl.replace(/\/$/, '') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <header className={styles.header}>
            <Link href="/" className={styles.logo}>
                <Svg name="ftz-shop-logo-multi-line-neg" className={styles.logoIcon} />
            </Link>

            {!hideLogInButton && (
                <div className={styles.buttons}>
                    <ButtonCircle
                        fetching={isLoadingLogin}
                        iconName="lock"
                        text={translate('header.login', 'Log ind')}
                        textPosition="bottom"
                        onClick={handleSignIn}
                    />
                </div>
            )}
        </header>
    );
};

export default HeaderRoot;
